.resume-body {
  position: absolute;
  width: 100%;
  height: 95%;
  justify-content: center;

  padding-top: 2em;
}

.resume-grid {
  text-align: center;
  justify-content: center;
}

.resume-frame {
  min-height: 75vh;
  width: auto;
  height: auto;
  border-radius: 10px;
  border: none;
}

.resume-grid h2 {
  font-family: "Anton", sans-serif;
}

/* Resize */
@media screen and (min-width: 1201px), screen and (min-height: 601px) {
  .resume-grid {
    width: fit-content;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 10px;
  }

  .resume-grid h2 {
    font-size: 3vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .resume-frame {
    min-width: 60vw;
  }
}

/* Resize*/
@media screen and (max-width: 1200px), screen and (max-height: 600px) {
  .resume-grid {
    width: fit-content;
    height: fit-content;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 10px;
  }

  .resume-grid h2 {
    font-size: 4vw;
    margin-top: 0;
    margin-bottom: 0;
  }

  .resume-frame {
    min-width: 90vw;
  }
}
