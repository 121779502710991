.contact-body {
  margin: auto;
  position: absolute;
  width: 100%;
  height: 95%;
  justify-content: center;
  padding-top: 2em;
}
.contact-text {
  color: white;
  font-family: "Anton";
}

/* Resize */
@media screen and (min-width: 1201px), screen and (min-height: 601px) {
  .contact-list i {
    color: white;
    font-size: 2.5vw;
    padding-right: 4rem;
    margin-left: 5rem;
  }

  .contact-grid p {
    /* font-family: "Oxygen", sans-serif; */
    font-size: 1.5vw;
  }

  .contact-grid h2 {
    font-family: "Anton", sans-serif;
    font-size: 3vw;
  }

  .contact-text {
    font-size: 2vw;
  }
}

/* Resize*/
@media screen and (max-width: 1200px), screen and (max-height: 600px) {
  .contact-list i {
    color: white;
    font-size: 3vw;
    padding-right: 0.5rem;
    margin-left: 5rem;
  }

  .contact-grid p {
    font-size: 3vw;
  }

  .contact-grid h2 {
    font-family: "Anton", sans-serif;
    font-size: 4vw;
  }

  .contact-text {
    font-size: 3vw;
  }
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  margin-bottom: 10px;
  border-radius: 10px;
}

.contact-grid hr {
  border-top: 5px ridge white;
  width: 50%;
  margin: auto;
}
