.landing-grid {
  text-align: center;
  position: absolute;
  top: auto;
  left: auto;
  width: 100%;
  height: 95%;
}

.avatar-img {
  max-height: auto;
  min-width: 150px;
  width: 20%;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.438);
}

.banner-text {
  background-color: rgba(0, 0, 0, 0.4);
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  font-size: 4vw;
  font-weight: bold;
  color: white;
}

.banner-text hr {
  border-top: 5px ridge white;
  width: 50%;
  margin: auto;
}

/* Resize */
@media screen and (min-width: 1201px), screen and (min-height: 601px) {
  .banner-text p {
    font-size: 1.5vw;
    color: white;
    padding: 1em;
  }
}

/* Resize*/
@media screen and (max-width: 1200px), screen and (max-height: 600px) {
  .banner-text p {
    font-size: 2.5vw;
    color: white;
    padding: 1em;
  }
}

.social-links {
  display: flex;
  justify-content: center;
  width: 50%;
  margin: auto;
}

.social-links i {
  color: white;
  font-size: 4vw;
  padding: 0.5em;
}
